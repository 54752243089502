.Navbar-color {
  background: #833ab4;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.HomePageGrid {
  background: #833ab4;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.avatar-img {
  height: 250px;
  padding-top: 3em;
  border-radius: 50%;
}
.pmi-img {
  height: 175px;
  padding-top: 2em;
  border-radius: 25%;
}
.Intro{
  text-align: justify;
}
.pic_container{
  padding:1em;
}
.pic {
  margin:5px
}

.Profile-title-text {
  background-color: #373b6e;
  opacity: 0.9;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.Profile-title-text h1 {
  font-size: 70px;
  font-weight: bold;
  color: white;
}

.Profile-title-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.Profile-title-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}
.Profile-subtitle-text {
  color: white;
  font-size: 18px;
  padding: 1em;
}

.Profile-subsubtitle-text {
  color: white;
  font-size: 35px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 2px;
}

.projects-grid {
  display: flex;
}

/* contact me Page styling */
.contactMe-Body {
  margin: auto;
  background: #e6e6e6;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contactMe-Grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contactMe-Grid h2 {
  font-family: Anton, sans-serif;
}

.contactMe-Grid-P {
  width: 75%;
  text-align: justify;
  margin: auto;
  padding-top: 1em;
  font-family: "Oxygen Mono", sans-serif;
  font-weight: bold;
}
